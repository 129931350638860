import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'

class AToughActPage extends React.Component {
  render() {
    const atap = this.props.data.contentfulAtapPage
    let sectionCount = 0
    return (
      <Layout location={this.props.location}>
        <section className="top">
          <Helmet title="A Tough Act Productions" />
          <h1>A Tough ACT Productions</h1>
        </section>
        <section>
          <header>
            <p className="section-number">0{(sectionCount += 1)}</p>
            <h2 id="summary">Summary</h2>
          </header>
          <div
            className="summary mt section-description"
            dangerouslySetInnerHTML={{
              __html: atap.summary.childMarkdownRemark.html,
            }}
          />
          <div className="banner mt">
            <a href="https://www.instagram.com/atoughactpro/">
              <GatsbyImage image={atap.bannerImage.gatsbyImageData} alt="" />
            </a>
          </div>
        </section>
      </Layout>
    )
  }
}

export default AToughActPage

export const pageQuery = graphql`
  query AtapPageQuery {
    contentfulAtapPage(id: { eq: "60f8a18d-2f53-5f8b-b7df-548c996264f3" }) {
      bannerImage {
        gatsbyImageData(width: 969, layout: CONSTRAINED)
      }
      summary {
        childMarkdownRemark {
          html
        }
      }
      title
    }
  }
`
